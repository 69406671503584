import {useState, useEffect, useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore, storage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { downloadContext } from '../../../App';

const initialState ={
    name:"",
    email:"",
    contactno:"",
    Job_Type:"",
    Serial_Number:"",
    Pin:"",
    PaymentMode:""
  }

const Job_application = () => {
    const [JobApp, SetJobApp] = useState(initialState)
    const [fileUpload, setFileUpload] = useState(null)
    const [progress, setProgress] = useState(null)

    const {setDownloadUrl} = useContext(downloadContext)

    const handleChange = (e) => {
      e.persist();
      SetJobApp((oldState) => ({
        ...oldState,
        [e.target.name]: e.target.value,
      }))
    }


    // useEffect(() => {
    //   const uploadFile = () => {
    //     const name = new Date().getTime() + fileUpload.name;
    //     const storageRef = ref(storage, fileUpload.name);
    //     const uploadTask = uploadBytesResumable(storageRef, fileUpload);
  
    //     uploadTask.on("state_changed", (snapshot) => {
    //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       setProgress(progress);
    //       switch(snapshot.state){
    //         case "paused":
    //           console.log("Upload is Pause");
    //           break;
    //         case "running":
    //           console.log("Upload is Running");
    //           break;
    //         default:
    //           break;
    //       }
    //     }, (error) => {
    //       console.log(error)
    //     },
    //       () => {
    //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //           SetJobApp((Prev) => ({...Prev, UploadDocs: downloadURL}))
    //           // console.log(downloadURL)
    //           setDownloadUrl(downloadURL)
    //         });
    //       }
    //     );
    //   };
  
    //   fileUpload && uploadFile()
    // }, [fileUpload])



    const postJobApp = async JobApp => {
      const time = Date.now()
      await firestore.collection('JobApp-Details').add({
        ...JobApp, 
        postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
      })
    }
  
    const handleSubmit = async () => {
      await postJobApp(JobApp);
      SetJobApp(initialState);
      // console.log(JobApp)
    }

  return (
    <div>
    <Form>
    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Name</Form.Label>
      <Form.Control 
        onChange={handleChange}
        name="name"
        value={JobApp.name}
        placeholder="Enter Your Full Name" 
        type='text'
      />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Email</Form.Label>
      <Form.Control
        onChange={handleChange} 
        name="email"
        value={JobApp.email}
        placeholder="Enter Email Address"
    />
    </Form.Group>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Contact No.</Form.Label>
        <Form.Control 
          onChange={handleChange} 
          name="contactno"
          value={JobApp.contactno}
          placeholder="Enter Telephone no."
        />
      </Form.Group>
    </Row>


    <Form.Group className='mt-3' controlId="formGridState">
        <Form.Label>Which kind of job do you want to apply ?</Form.Label>
        <Form.Select
            onChange={handleChange} 
            name="Job_Type"
            value={JobApp.Job_Type}
            defaultValue="Choose..."
          >
          <option>Choose...</option>
          <option>IMMIGRATION</option>
          <option>POLICE</option>
          <option>ARMY</option>
          <option>FIRE SERVICE</option>
          <option>PRISONS SERVICE</option>
          <option>ARMBULANCE SERVICE</option>
          <option>LOCAL GOVERNMENT</option>
          <option>GHANA EDUCATION SERVICE</option>
        </Form.Select>
    </Form.Group>

     <Form.Group className="position-relative mb-3 mt-3">
          <Form.Label>Enter serial number if you've bought the form already</Form.Label>
          <Form.Control
            onChange={handleChange} 
            name="Serial_Number"
            value={JobApp.Serial_Number}
            type="text"
        />
      </Form.Group>

      <Form.Group className="position-relative mb-3 mt-3">
          <Form.Label>Enter Pin if you've bought the form already</Form.Label>
          <Form.Control
            onChange={handleChange} 
            name="Pin"
            value={JobApp.Pin}
            type="text"
        />
      </Form.Group>

    <Form.Group className='mt-3' controlId="formGridState">
        <Form.Label>Payment Mode</Form.Label>
        <Form.Select
            onChange={handleChange} 
            name="PaymentMode"
            value={JobApp.PaymentMode}
            defaultValue="Choose..."
          >
          <option>Choose...</option>
          <option>Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</option>
          <option>Cash</option>
        </Form.Select>
    </Form.Group>
    
    <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
      <Form.Label>If Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</Form.Label>
    </Form.Group>

    <Button variant="primary" className='mt-3' onClick={handleSubmit}>
      Submit
    </Button>
  </Form>
  </div>
  )
}

export default Job_application
