import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "./Style.css"
import Footer from './views/Footer';
import { Helmet } from 'react-helmet-async';

const Home = () => {
 
  return (
    <>
    <Helmet>
      <title>Adwinpa Business</title>
      <meta name='description' content='We use quality materials to produce quality work at all times. We always aim to make customers admire our work through innovation and improvement.' />
      <link rel="canonical" href="/" />
    </Helmet>
   <div style={{backgroundSize:"cover", height: "500px",}}>

<Carousel>
      <Carousel.Item>
        <img
          style={{ height:"500px"}}
          className="d-block w-100"
          src="https://wallpapercave.com/wp/wp5172204.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h1> WELCOME TO ADWINPA BUSINESS</h1>
          <h3>WE GET YOU KNOWN.</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{height:"500px"}}
          className="d-block w-100"
          src="https://images.hdqwalls.com/download/three-colour-mix-abstract-4k-8n-1920x1080.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h1> WELCOME TO ADWINPA BUSINESS</h1>
          <h3>WE GET YOU KNOWN.</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{height:"500px"}}
          className="d-block w-100"
          src="https://wallpaperset.com/w/full/d/5/4/397930.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h1> WELCOME TO ADWINPA BUSINESS</h1>
          <h3>WE GET YOU KNOWN.</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    
   </div>
   </>
  )
}

export default Home
