import {useState} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore } from '../../../firebase/config';

const initialState ={
  name:"",
  email:"",
  contactno:"",
  typeofCard:"",
  quantity:"",
  PaymentMode:""
}

const Buy_card_check_results = () => {
  const [buyCardCheckResults, SetBuyCardCheckResults] = useState(initialState)

  const handleChange = (e) => {
    e.persist();
    SetBuyCardCheckResults((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }))
  }

  const postBuyCardCheckResults = async buyCardCheckResults => {
    const time = Date.now()
    await firestore.collection('Buy-card-check-results').add({
      ...buyCardCheckResults,
      postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
    })
  }

  const handleSubmit = async () => {
    await postBuyCardCheckResults(buyCardCheckResults);
    SetBuyCardCheckResults(initialState);
    // console.log(buyCardCheckResults)
  }


  return (
    <div>
      <Form>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          onChange={handleChange}
          name="name"
          value={buyCardCheckResults.name}
          placeholder="Enter Your Full Name" 
          type='text'
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Email Address for receiving card </Form.Label>
        <Form.Control
          onChange={handleChange} 
          name="email"
          value={buyCardCheckResults.email}
          placeholder="Enter Email Address"
      />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Contact No.</Form.Label>
          <Form.Control 
            onChange={handleChange} 
            name="contactno"
            value={buyCardCheckResults.contactno}
            placeholder="Enter Telephone no."
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>what type of card do you want to buy  ?</Form.Label>
          <Form.Control 
            onChange={handleChange} 
            name="typeofCard"
            value={buyCardCheckResults.typeofCard}
            placeholder="Eg: BECE Results Checker or WASSCE result checker or NOV-DEC result checker"
          />
        </Form.Group>
      </Row>

       <Form.Group className="position-relative mb-3 mt-3">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              onChange={handleChange} 
              name="quantity"
              value={buyCardCheckResults.quantity}
              type="text"
          />
        </Form.Group>

        <Form.Group className='mt-3' controlId="formGridState">
            <Form.Label>Payment Mode</Form.Label>
            <Form.Select
                onChange={handleChange} 
                name="PaymentMode"
                value={buyCardCheckResults.PaymentMode}
                defaultValue="Choose..."
                >
                <option>Choose...</option>
                <option>Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</option>
                <option>Cash</option>
            </Form.Select>
        </Form.Group>
      
      <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
        <Form.Label>If Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</Form.Label>
      </Form.Group>

      <Button variant="primary" className='mt-3' onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
    </div>
  )
}

export default Buy_card_check_results
