import {useState, useEffect, useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore, storage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { downloadContext } from '../../../App';

const initialState = {
  name:"",
  email:"",
  contactno:"",
  Printout:"",
  upload_file:"",
  numberofprintouts:"",
  Quantity_copies:"",
  Number_Of_Copies:"",
  TimeToDeliver:"",
  Description:"",
  PaymentMode:""
}

const Deliver_it_to_me = () => {
  const [printDelivery, SetPrintDelivery] = useState(initialState)
  const [fileUpload, setFileUpload] = useState(null)
  const [progress, setProgress] = useState(null)

  const {setDownloadUrl} = useContext(downloadContext)


  const handleChange = (e) => {
    e.persist();
    SetPrintDelivery((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + fileUpload.name;
      const storageRef = ref(storage, fileUpload.name);
      const uploadTask = uploadBytesResumable(storageRef, fileUpload);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch(snapshot.state){
          case "paused":
            console.log("Upload is Pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      }, (error) => {
        console.log(error)
      },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            SetPrintDelivery((Prev) => ({...Prev, UploadDocs: downloadURL}))
            // console.log(downloadURL)
            setDownloadUrl(downloadURL)
          });
        }
      );
    };

    fileUpload && uploadFile()
  }, [fileUpload])

  const postPrintDelivery = async printDelivery => {
    const time = Date.now()
    await firestore.collection('print-delivery').add({
      ...printDelivery, 
      postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
    })
  }


  const handleSubmit = async () => {
    await postPrintDelivery(printDelivery);
    SetPrintDelivery(initialState);
    // console.log(printDelivery)
  }


  return (
    <div>
      <Form>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          onChange={handleChange}
          name="name"
          value={printDelivery.name}
          placeholder="Enter Your Full Name" 
          type='text'
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Email</Form.Label>
        <Form.Control
          onChange={handleChange} 
          name="email"
          value={printDelivery.email}
          placeholder="Enter Email Address"
      />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Contact No.</Form.Label>
          <Form.Control 
            onChange={handleChange} 
            name="contactno"
            value={printDelivery.contactno}
            placeholder="Enter Telephone no."
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Colored Print out Or Black & White ?</Form.Label>
          <Form.Control 
            onChange={handleChange} 
            name="Printout"
            value={printDelivery.Printout}
            placeholder="Colored Print out Or Black & White"
          />
        </Form.Group>
      </Row>

       <Form.Group className="position-relative mb-3 mt-3">
            <Form.Label>Upload File to be printed</Form.Label>
            <Form.Control
              onChange={(event) => {setFileUpload(event.target.files[0])}} 
              name="upload_file"
              // value={printDelivery.upload_file}
              type="file"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>How many Printouts ?</Form.Label>
            <Form.Control
              onChange={handleChange} 
              name="numberofprintouts"
              value={printDelivery.numberofprintouts}
              placeholder="How many Printouts" 
            />
        </Form.Group>


        <Form.Group className='mt-3 mb-3' controlId="formGridState">
              <Form.Label>Do you want Photocopy ?</Form.Label>
              <Form.Select
                  onChange={handleChange} 
                  name="Quantity_copies"
                  value={printDelivery.Quantity_copies}
                  defaultValue="Choose..."
                >
                <option>Choose...</option>
                <option>Yes</option>
                <option>No</option>
              </Form.Select>
          </Form.Group>


            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>If yes how many copies do you want ?</Form.Label>
                <Form.Control
                  onChange={handleChange} 
                  name="Number_Of_Copies"
                  value={printDelivery.Number_Of_Copies}
                  placeholder="how many copies do you want ?" 
                />
            </Form.Group>


        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Time you want it Delivered ?</Form.Label>
            <Form.Control
              className="cursor-pointer"
              onChange={handleChange} 
              name="TimeToDeliver"
              value={printDelivery.TimeToDeliver}
              placeholder="Time To Pick" 
              type='datetime-local'
            />
        </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Describe your work if not mentioned above</Form.Label>
        <Form.Control
          onChange={handleChange} 
          name="Description"
          value={printDelivery.Description}
          placeholder="Describe your work if not mentioned above" />
      </Form.Group>

      <Form.Group className='mt-3' controlId="formGridState">
          <Form.Label>Payment Mode</Form.Label>
          <Form.Select
              onChange={handleChange} 
              name="PaymentMode"
              value={printDelivery.PaymentMode}
              defaultValue="Choose..."
            >
            <option>Choose...</option>
            <option>Momo Make payment on <span>055 944 2580</span> Richard Qwofie</option>
            <option>Cash</option>
          </Form.Select>
       </Form.Group>
      
      <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
        <Form.Label>If Momo, Make payment on <span>055 944 2580</span> Richard Qwofie</Form.Label>
      </Form.Group>

      <Button variant="primary" className='mt-3' onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
    </div>
  )
}

export default Deliver_it_to_me
