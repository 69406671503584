
import { createContext, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Contact from "./components/Contact";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Print_works from "./components/form_main_nav/Print_works";
import Check_Results from "./components/form_main_nav/Check_Results";
import Design_works from "./components/Design_works";
import Admin from "./components/Admin";
// import { firestore } from "./firebase/config"


export const downloadContext = createContext()


function App() {
  const [downloadUrl, setDownloadUrl] = useState()
  return (
    <downloadContext.Provider value={{downloadUrl, setDownloadUrl}}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="print" element={<Print_works />} />
          <Route path="check_results" element={<Check_Results />} />
          <Route path="print" element={<Print_works />} />
          <Route path="design_works" element={<Design_works />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </downloadContext.Provider>
  );
}

export default App;
