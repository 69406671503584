import React, { useEffect, useState } from 'react'
import { Outlet, Link } from "react-router-dom";
import Body from './views/Body';
import Footer from './views/Footer';
import Navigate from './views/Nav';
import {firestore} from '../firebase/config';

const Layout = () => {
  const [printPick, setPrintPick] = useState([])

  const fetchPrintPick = async () => {
    const req = await firestore.collection('print-pick').orderBy("postedOn","desc").get();
    const tempPrint = req.docs.map((print) =>({...print.data(), id:print.id}));
    setPrintPick(tempPrint)
  }

  useEffect(() => {
    fetchPrintPick();
  },[])
  
  return (
    <>
      <Navigate />
      <Outlet />
      <Body />
      <Footer />
    </>
  )
};

export default Layout;