import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "./Footer.css"

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className='fixed bottom-0 m-2 w-full'>
        <Card className="text-center">
        <Card.Footer className="text-muted">{`Copyright © Adwinpa Business ${year}`}</Card.Footer>
        </Card>
    </div>
  )
}

export default Footer
