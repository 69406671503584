import {useState, useEffect, useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore, storage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { downloadContext } from '../../../App';

const initialState ={
    name:"",
    email:"",
    contactno:"",
    Passport_Type:"",
    Date_of_delivery:"",
    Description:"",
    PaymentMode:""
  }

const Passport = () => {
    const [passport, SetPassport] = useState(initialState)
    const [fileUpload, setFileUpload] = useState(null)
    const [progress, setProgress] = useState(null)

    const {setDownloadUrl} = useContext(downloadContext)

    const handleChange = (e) => {
      e.persist();
      SetPassport((oldState) => ({
        ...oldState,
        [e.target.name]: e.target.value,
      }))
    }

    useEffect(() => {
      const uploadFile = () => {
        const name = new Date().getTime() + fileUpload.name;
        const storageRef = ref(storage, fileUpload.name);
        const uploadTask = uploadBytesResumable(storageRef, fileUpload);
  
        uploadTask.on("state_changed", (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch(snapshot.state){
            case "paused":
              console.log("Upload is Pause");
              break;
            case "running":
              console.log("Upload is Running");
              break;
            default:
              break;
          }
        }, (error) => {
          console.log(error)
        },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              SetPassport((Prev) => ({...Prev, UploadDocs: downloadURL}))
              // console.log(downloadURL)
              setDownloadUrl(downloadURL)
            });
          }
        );
      };
  
      fileUpload && uploadFile()
    }, [fileUpload])


    const postPassport = async passport => {
      const time = Date.now()
      await firestore.collection('Passport-Details').add({
        ...passport, 
        postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
      })
    }
  
  
    const handleSubmit = async () => {
      await postPassport(passport);
      SetPassport(initialState);
      // console.log(passport)
    }

  return (
    <div>
    <Form>
    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Name</Form.Label>
      <Form.Control 
        onChange={handleChange}
        name="name"
        value={passport.name}
        placeholder="Enter Your Full Name" 
        type='text'
      />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Email</Form.Label>
      <Form.Control
        onChange={handleChange} 
        name="email"
        value={passport.email}
        placeholder="Enter Email Address"
    />
    </Form.Group>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Contact No.</Form.Label>
        <Form.Control 
          onChange={handleChange} 
          name="contactno"
          value={passport.contactno}
          placeholder="Enter Telephone no."
        />
      </Form.Group>
    </Row>


    <Form.Group className='mt-3' controlId="formGridState">
        <Form.Label>What type of passport do you want ?</Form.Label>
        <Form.Select
            onChange={handleChange} 
            name="Passport_Type"
            value={passport.Passport_Type}
            defaultValue="Choose..."
          >
          <option disabled>Choose...</option>
          <option>New</option>
          <option>Renew</option>
        </Form.Select>
    </Form.Group>

      <Form.Group className='mt-3' controlId="formGridState">
          <Form.Label>When do you want your New/Renewed passport ?</Form.Label>
          <Form.Select
              onChange={handleChange} 
              name="Date_of_delivery"
              value={passport.Date_of_delivery}
              defaultValue="Choose..."
              >
              <option disabled>Choose...</option>
              <option>One Week</option>
              <option>Two Weeks</option>
              <option>One Month</option>
              <option>Three Months</option>
          </Form.Select>
      </Form.Group>

     <Form.Group className="position-relative mb-3 mt-3">
          <Form.Label>Upload your birth Certificate</Form.Label>
          <Form.Control
            onChange={(event) => {setFileUpload(event.target.files[0])}} 
            name="Upload_Birth_Cert"
            type="file"
        />
      </Form.Group>

    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Describe your work if not mentioned above</Form.Label>
      <Form.Control
        onChange={handleChange} 
        name="Description"
        value={passport.Description}
        placeholder="Describe your work if not mentioned above" />
    </Form.Group>

    <Form.Group className='mt-3' controlId="formGridState">
        <Form.Label>Payment Mode</Form.Label>
        <Form.Select
            onChange={handleChange} 
            name="PaymentMode"
            value={passport.PaymentMode}
            defaultValue="Choose..."
          >
          <option disabled>Choose...</option>
          <option>Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</option>
          <option>Cash</option>
        </Form.Select>
    </Form.Group>
    
    <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
      <Form.Label>If Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</Form.Label>
    </Form.Group>

    <Button variant="primary" className='mt-3' onClick={handleSubmit}>
      Submit
    </Button>
  </Form>
  </div>
  )
}

export default Passport
