import React, { useEffect, useState, useContext } from 'react'
import ReactPaginate from 'react-paginate';
import { firestore } from '../firebase/config'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import  {collection, doc, onSnapshot}  from 'firebase/firestore'
import { Link } from 'react-router-dom';
import { downloadContext } from '../App';


const Modal = ({ item, onClose }) => {
    return (
      <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white p-8 rounded-lg'>
          <h2 className='text-xl font-bold mb-4'>Details</h2>
          <p><strong>Posted On:</strong> {item.postedOn}</p>
          <p><strong>Name:</strong> {item.name}</p>
          <p><strong>Email:</strong> {item.email}</p>
          <p><strong>Tel No.:</strong> {item.contactno}</p>
          <p><strong>Print Out Type:</strong> {item.Print_Out_Type}</p>
          <p><strong>Quantity:</strong> {item.Number_Of_Printouts}</p>
          <p><strong>Do you want Photocopy ?:</strong> {item.Quantity_copies}</p>
          <p><strong>Number Of Copies:</strong> {item.Number_Of_Copies}</p>
          <p><strong>Mode of Payment:</strong> {item.PaymentMode}</p>
          <p><strong>Time To Pick:</strong> {item.TimeToPick}</p>
          <p><strong>Description:</strong> {item.Description}</p>
          <button className='mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg' onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };



  const ModalDelivery = ({ item, onClose }) => {
    return (
      <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white p-8 rounded-lg'>
          <h2 className='text-xl font-bold mb-4'>Details</h2>
          <p><strong>Posted On:</strong> {item.postedOn}</p>
          <p><strong>Name:</strong> {item.name}</p>
          <p><strong>Email:</strong> {item.email}</p>
          <p><strong>Tel No.:</strong> {item.contactno}</p>
          <p><strong>Print Out Type:</strong> {item.Print_Out_Type}</p>
          <p><strong>Quantity:</strong> {item.Number_Of_Printouts}</p>
          <p><strong>Do you want Photocopy ?:</strong> {item.Quantity_copies}</p>
          <p><strong>Number Of Copies:</strong> {item.Number_Of_Copies}</p>
          <p><strong>Mode of Payment:</strong> {item.PaymentMode}</p>
          <p><strong>Time To Pick:</strong> {item.TimeToPick}</p>
          <p><strong>Description:</strong> {item.Description}</p>
          <button className='mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg' onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };


  const ITEMS_PER_PAGE = 5;
  const MAX_DESCRIPTION_LENGTH = 5; // Maximum number of characters to show in description

const Admin = () => {
    const [users, setUsers] = useState([])
    const [userPrintDelivery, setUserPrintDelivery] = useState([])
    const [userCheckResultsOnly, setUserCheckResultsOnly] = useState([])
    const [userBuyCardCheckResults, setUserBuyCardCheckResults] = useState([])
    const [BirthCertificate, SetBirthCertificate] = useState([])
    const [passport, setPassport] = useState([])
    const [university, setUniversity] = useState([])
    const [JobApp, setJobApp] = useState([])

    const [loading, setLoading] = useState(false);

    const {downloadUrl} = useContext(downloadContext)




//     const [showModal, setShowModal] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelivery, setShowModalDelivery] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemDelivery, setSelectedItemDelivery] = useState(null);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const openModalDelivery = (item) => {
    setSelectedItemDelivery(item);
    setShowModalDelivery(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeModalDelivery = () => {
    setShowModalDelivery(false);
  };


  const offset = currentPage * ITEMS_PER_PAGE;
  const currentItems = users.slice(offset, offset + ITEMS_PER_PAGE);
    // console.log("Context Download : ",downloadUrl)

    useEffect(() => {
        setLoading(true);
        const unsub = onSnapshot(collection(firestore, "print-pick"), (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
                list.push({id: doc.id, ...doc.data()})
            });
            setUsers(list);
            setLoading(false)
        }, (error) => {
            console.log(error)
        });


        

        return () => {
            unsub();
            SubPrintDelivery();
            SubPrintCheckResultsOnly();
            SubPrintBuyCardCheckResultsOnly();
            SubBirthCertificate();
            SubPassport();
            SubUniversity();
            SubJob();
        }


    }, []);

    // Print Delivery
    const SubPrintDelivery = onSnapshot(collection(firestore, "print-delivery"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        setUserPrintDelivery(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });

    // Print Check Results Only
    const SubPrintCheckResultsOnly = onSnapshot(collection(firestore, "Check-Results-Only"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        setUserCheckResultsOnly(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });


    // Print Buy Card Check Results Only
    const SubPrintBuyCardCheckResultsOnly = onSnapshot(collection(firestore, "Buy-card-check-results"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        setUserBuyCardCheckResults(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });


    // Birth Certificate
    const SubBirthCertificate = onSnapshot(collection(firestore, "Birth-Certificate-Details"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        SetBirthCertificate(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });

    // Passport Details
    const SubPassport = onSnapshot(collection(firestore, "Passport-Details"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        setPassport(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });

     // University Application Details
     const SubUniversity = onSnapshot(collection(firestore, "UniversityApp-Details"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        setUniversity(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });


     // Job Application Details
    const SubJob = onSnapshot(collection(firestore, "JobApp-Details"), (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
            list.push({id: doc.id, ...doc.data()})
        });
        setJobApp(list);
        setLoading(false)
    }, (error) => {
        console.log(error)
    });

    console.log("End jobs : ", JobApp)

  return (
        <Container className="mt-2">

<div className='table-responsive'>
    <p>PRINT AND PICK IT UP FROM THE SHOP</p>
      <table className='table w-full border-collapse border border-gray-300'>
        <thead>
          <tr className='bg-sky-700'>
            <th className='p-2 border border-gray-300'>Posted&nbsp;On</th>
            <th className='p-2 border border-gray-300'>Name</th>
            <th className='p-2 border border-gray-300'>Email</th>
            <th className='p-2 border border-gray-300'>Tel No.</th>
            <th className='p-2 border border-gray-300'>Print&nbsp;Out&nbsp;Type</th>
            <th className='p-2 border border-gray-300'>Quantity</th>
            <th className='p-2 border border-gray-300'>Photocopy</th>
            <th className='p-2 border border-gray-300'>Number&nbsp;Of&nbsp;Copies</th>
            <th className='p-2 border border-gray-300'>Mode&nbsp;of&nbsp;Payment</th>
            <th className='p-2 border border-gray-300'>Time&nbsp;To&nbsp;Pick</th>
            <th className='p-2 border border-gray-300'>Description</th>
            <th className='p-2 border border-gray-300'>Document</th>
            <th className='p-2 border border-gray-300'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* {users && users.map((item, index) => ( */}
          {currentItems && currentItems.map((item, index) => (
            <tr key={index}>
              <td className='p-2 border border-gray-300'>
                {/* {item.postedOn} */}
                {item.postedOn.length > MAX_DESCRIPTION_LENGTH ? (
                    <>
                      {item.postedOn.slice(0, MAX_DESCRIPTION_LENGTH)}...
                    </>
                  ) : (
                    item.postedOn
                  )}
              </td>
              <td className='p-2 border border-gray-300'>
                {/* {item.name} */}
                {item.name.length > MAX_DESCRIPTION_LENGTH ? (
                    <>
                      {item.name.slice(0, MAX_DESCRIPTION_LENGTH)}...
                    </>
                  ) : (
                    item.name
                  )}
              </td>
              <td className='p-2 border border-gray-300'>{item.email}</td>
              <td className='p-2 border border-gray-300'>{item.contactno}</td>
              <td className='p-2 border border-gray-300'>
                {/* {item.Print_Out_Type} */}
                {item.Print_Out_Type.length > MAX_DESCRIPTION_LENGTH ? (
                    <>
                      {item.Print_Out_Type.slice(0, MAX_DESCRIPTION_LENGTH)}...
                    </>
                  ) : (
                    item.Print_Out_Type
                  )}
              </td>
              <td className='p-2 border border-gray-300'>{item.Number_Of_Printouts} </td>
              <td className='p-2 border border-gray-300'>{item.Quantity_copies}</td>
              <td className='p-2 border border-gray-300'>{item.Number_Of_Copies}</td>
              <td className='p-2 border border-gray-300'>
                {/* {item.PaymentMode} */}
                {item.PaymentMode.length > MAX_DESCRIPTION_LENGTH ? (
                    <>
                      {item.PaymentMode.slice(0, MAX_DESCRIPTION_LENGTH)}...
                    </>
                  ) : (
                    item.PaymentMode
                  )}
              </td>
              <td className='p-2 border border-gray-300'>
                {/* {item.TimeToPick} */}
                {item.TimeToPick.length > MAX_DESCRIPTION_LENGTH ? (
                    <>
                      {item.TimeToPick.slice(0, MAX_DESCRIPTION_LENGTH)}...
                    </>
                  ) : (
                    item.TimeToPick
                  )}
              </td>
              <td className='p-2 border border-gray-300'>
                {/* {item.Description} */}
                  {item.Description.length > MAX_DESCRIPTION_LENGTH ? (
                    <>
                      {item.Description.slice(0, MAX_DESCRIPTION_LENGTH)}...
                    </>
                  ) : (
                    item.Description
                  )}
                </td>
                {/* <td className='p-2 border border-gray-300'>
                    <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                </td> */}
                <td className='p-2 border border-gray-300'>
                  {item.UploadDocs.endsWith('.jpg') || item.UploadDocs.endsWith('.png') ? (
                    <img src={item.UploadDocs} alt='Uploaded Image' className='w-16 h-16 object-cover' />
                  ) : item.UploadDocs.endsWith('.pdf') ? (
                    <a href={item.UploadDocs} target='_blank' rel='noopener noreferrer' className='m-3'>View PDF</a>
                  ) : (
                    <a href={item.UploadDocs} download className='m-3'>
                        {/* {item.UploadDocs} */}
                        {/* Documents */}
                        <Button className='px-4 bg-blue-500 text-white rounded-lg' >Documents</Button>
                    </a>
                  )}
                </td>
              <td className='p-2 border border-gray-300'>
                <Button className='px-4 bg-blue-500 text-white rounded-lg' onClick={() => openModal(item)}>View</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {users.length > ITEMS_PER_PAGE && (
        <div className='pagination'>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={Math.ceil(users.length / ITEMS_PER_PAGE)}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
          />
        </div>
      )}
      {showModal && <Modal item={selectedItem} onClose={closeModal} />}
    </div>
            <section className='py-4 py-lg-5 container'>
                {/* <div className='row justify-center align-center'>
                <h1 className='text-center'>Print And Pick it Up</h1>
            {users && users.map((item) => (
                <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                    <div className='card p-0 overflow-hidden h-100 shadow'>
                        <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                        <div className='card-body'>
                            <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                            <h4 className='card-title'>{item.name}</h4>
                            <h6 className='card-title'>{item.email}</h6>
                            <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                            <h6 className='card-title'>Print Out Type : {item.Print_Out_Type}</h6>
                            <h6 className='card-title'>Quantity : {item.Number_Of_Printouts}</h6>
                            <h6 className='card-title'>Do you want Photocopy ? : {item.Quantity_copies}</h6>
                            <h6 className='card-title'>Number Of Copies : {item.Number_Of_Copies}</h6>
                            <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                            <h6 className='card-title'>Time To Pick : {item.TimeToPick}</h6>
                            <h6 className='card-title'>Description : {item.Description}</h6>
                            <Button variant="primary">View</Button>
                        </div>
                    </div>
                </div>

            ))}
                </div> */}


                {/* Print Delivery */}
                {/* <div className='row justify-center align-center'>
                    <hr className='' />
                    <h1 className='text-center m-3'>Print Delivery</h1>
                  {userPrintDelivery && userPrintDelivery.map((item) => (
                      <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                          <div className='card p-0 overflow-hidden h-100 shadow'>
                              <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                              <div className='card-body'>
                                  <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                  <h4 className='card-title'>{item.name}</h4>
                                  <h6 className='card-title'>{item.email}</h6>
                                  <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                  <h6 className='card-title'>Print Out Type : {item.Print_Out_Type}</h6>
                                  <h6 className='card-title'>Quantity : {item.Number_Of_Printouts}</h6>
                                  <h6 className='card-title'>Do you want Photocopy ? : {item.Quantity_copies}</h6>
                                  <h6 className='card-title'>Number Of Copies : {item.Number_Of_Copies}</h6>
                                  <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                  <h6 className='card-title'>Time To Pick : {item.TimeToPick}</h6>
                                  <h6 className='card-title'>Description : {item.Description}</h6>
                                  <Button variant="primary">View</Button>
                              </div>
                          </div>
                      </div>
      
                    ))}
                </div> */}

                  <div className='table-responsive'>

                
                    <table className='table w-full border-collapse border border-gray-300'>
                      <thead>
                        <tr>
                          <th className='p-2 border border-gray-300'>Posted&nbsp;On</th>
                          <th className='p-2 border border-gray-300'>Name</th>
                          <th className='p-2 border border-gray-300'>Email</th>
                          <th className='p-2 border border-gray-300'>Tel No.</th>
                          <th className='p-2 border border-gray-300'>Print&nbsp;Out&nbsp;Type</th>
                          <th className='p-2 border border-gray-300'>Quantity</th>
                          <th className='p-2 border border-gray-300'>Photocopy</th>
                          <th className='p-2 border border-gray-300'>Number&nbsp;Of&nbsp;Copies</th>
                          <th className='p-2 border border-gray-300'>Mode&nbsp;of&nbsp;Payment</th>
                          <th className='p-2 border border-gray-300'>Time&nbsp;To&nbsp;Pick</th>
                          <th className='p-2 border border-gray-300'>Description</th>
                          <th className='p-2 border border-gray-300'>Document</th>
                          <th className='p-2 border border-gray-300'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userPrintDelivery && userPrintDelivery.map((item, index) => (
                          <tr key={index}>
                            <td>{item.postedOn}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.contactno}</td>
                            <td>{item.Print_Out_Type}</td>
                            <td>{item.Number_Of_Printouts}</td>
                            <td>{item.Quantity_copies}</td>
                            <td>{item.Number_Of_Copies}</td>
                            <td>{item.PaymentMode}</td>
                            <td>{item.TimeToPick}</td>
                            <td>{item.Description}</td>
                            {/* <td><a href={item.UploadDocs} download>{item.UploadDocs}</a></td> */}
                            <td className='p-2 border border-gray-300'>
                              {item.UploadDocs.endsWith('.jpg') || item.UploadDocs.endsWith('.png') ? (
                                <img src={item.UploadDocs} alt='Uploaded Image' className='w-16 h-16 object-cover' />
                              ) : item.UploadDocs.endsWith('.pdf') ? (
                                <a href={item.UploadDocs} target='_blank' rel='noopener noreferrer' className='m-3'>View PDF</a>
                              ) : (
                                <a href={item.UploadDocs} download className='m-3'>
                                    {/* {item.UploadDocs} */}
                                    {/* Documents */}
                                    <Button className='px-4 bg-blue-500 text-white rounded-lg' >Documents</Button>
                                </a>
                              )}
                            </td>
                            {/* <td><Button variant="primary">View</Button></td> */}
                            <Button className='px-4 bg-blue-500 text-white rounded-lg' onClick={() => openModalDelivery(item)}>View</Button>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {showModalDelivery && <Modal item={selectedItemDelivery} onClose={closeModalDelivery} />}
                  </div>



                    {/* Print Check Results Only */}
                    <div className='row justify-center align-center'>
                            <hr className='' />
                        <h1 className='text-center m-3'>Print Check Results Only</h1>
                        {userCheckResultsOnly && userCheckResultsOnly.map((item) => (
                            <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                                <div className='card p-0 overflow-hidden h-100 shadow'>
                                    {/* <a href={item.UploadDocs} target="_blank" download="kwame"><img src={item.UploadDocs}/></a> */}
                                    <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                                    <div className='card-body'>
                                        <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                        <h4 className='card-title'>{item.name}</h4>
                                        <h6 className='card-title'>{item.email}</h6>
                                        <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                        <h6 className='card-title'>Quantity : {item.quantity}</h6>
                                        <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                        <h6 className='card-title'>Time To Pick : {item.time}</h6>
                                        <Button variant="primary">View</Button>
                                    </div>
                                </div>
                            </div>
            
                        ))}
                    </div>

                     {/* Print Buy Card Check Results Only */}
                     <div className='row justify-center align-center'>
                            <hr className='' />
                        <h1 className='text-center m-3'>Print Buy Card Check Results Only</h1>
                        {userBuyCardCheckResults && userBuyCardCheckResults.map((item) => (
                            <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                                <div className='card p-0 overflow-hidden h-100 shadow'>
                                    <div className='card-body'>
                                        <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                        <h4 className='card-title'>{item.name}</h4>
                                        <h6 className='card-title'>{item.email}</h6>
                                        <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                        <h6 className='card-title'>Quantity : {item.quantity}</h6>
                                        <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                        <h6 className='card-title'>Time To Pick : {item.time}</h6>
                                        <Button variant="primary">View</Button>
                                    </div>
                                </div>
                            </div>
            
                        ))}
                    </div>


                    {/* Birth Certificate Details */}
                    <div className='row justify-center align-center'>
                            <hr className='' />
                        <h1 className='text-center m-3'>Birth Certificate Details</h1>
                        {BirthCertificate && BirthCertificate.map((item) => (
                            <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                                <div className='card p-0 overflow-hidden h-100 shadow'>
                                <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                                    <div className='card-body'>
                                        <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                        <h4 className='card-title'>{item.name}</h4>
                                        <h6 className='card-title'>{item.email}</h6>
                                        <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                        <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                        <h6 className='card-title'>Time To Pick : {item.Date_of_delivery}</h6>
                                        <Button variant="primary">View</Button>
                                    </div>
                                </div>
                            </div>
            
                        ))}
                    </div>


                    {/* Passport Details */}
                    <div className='row justify-center align-center'>
                            <hr className='' />
                        <h1 className='text-center m-3'>Passport Details</h1>
                        {passport && passport.map((item) => (
                            <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                                <div className='card p-0 overflow-hidden h-100 shadow'>
                                <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                                    <div className='card-body'>
                                        <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                        <h4 className='card-title'>{item.name}</h4>
                                        <h6 className='card-title'>{item.email}</h6>
                                        <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                        <h6 className='card-title'>Passport Type : {item.Passport_Type}</h6>
                                        <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                        <h6 className='card-title'>Time To Pick : {item.Date_of_delivery}</h6>
                                        <Button variant="primary">View</Button>
                                    </div>
                                </div>
                            </div>
            
                        ))}
                    </div>


                    {/* University Application Details */}
                    <div className='row justify-center align-center'>
                            <hr className='' />
                        <h1 className='text-center m-3'>University Application Details</h1>
                        {university && university.map((item) => (
                            <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                                <div className='card p-0 overflow-hidden h-100 shadow'>
                                <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                                    <div className='card-body'>
                                        <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                        <h4 className='card-title'>Name of Student : {item.name}</h4>
                                        <h4 className='card-title'>Name Of Institution : {item.Name_Of_Institution}</h4>
                                        <h6 className='card-title'>Email : {item.email}</h6>
                                        <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                        <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                        <h6 className='card-title'>Time To Pick : {item.Time_To_Pick_It_Up}</h6>
                                        <Button variant="primary">View</Button>
                                    </div>
                                </div>
                            </div>
            
                        ))}
                    </div>


                    {/* Job Application Details */}
                    <div className='row justify-center align-center'>
                            <hr className='' />
                        <h1 className='text-center m-3'>Job Application Details</h1>
                        {JobApp && JobApp.map((item) => (
                            <div className='col-11 col-md-6 col-lg-3 mx-0 mb-4'>
                                <div className='card p-0 overflow-hidden h-100 shadow'>
                                <a href={item.UploadDocs} download className='m-3'>{item.UploadDocs}</a>
                                    <div className='card-body'>
                                        <h6 className='card-title'>Posted On : {item.postedOn}</h6>
                                        <h4 className='card-title'>Name : {item.name}</h4>
                                        <h6 className='card-title'>Email : {item.email}</h6>
                                        <h4 className='card-title'>Tel No. : {item.contactno}</h4>
                                        <h6 className='card-title'>Job Type. : {item.Job_Type}</h6>
                                        <h6 className='card-title'>Serial Number. : {item.Serial_Number}</h6>
                                        <h6 className='card-title'>Pin. : {item.Pin}</h6>
                                        <h6 className='card-title'>Mode of Payment : {item.PaymentMode}</h6>
                                        <h6 className='card-title'>Time To Pick : {item.Time_To_Pick_It_Up}</h6>
                                        <Button variant="primary">View</Button>
                                    </div>
                                </div>
                            </div>
            
                        ))}
                    </div>
            </section>
        </Container>
  )
}

export default Admin
