 // Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app"
import { getStorage } from "firebase/storage";
import "firebase/compat/firestore"
// import "firebase/compat/storage"
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries

 // Your web app's Firebase configuration
 // For Firebase JS SDK v7.20.0 and later, measurementId is optional
 const firebaseConfig = {
   apiKey: "AIzaSyCZafQVpqg87vHaIfOqEgzDupA_S79oAWU",
   authDomain: "adwinpa-businessdb.firebaseapp.com",
   projectId: "adwinpa-businessdb", 
   storageBucket: "adwinpa-businessdb.appspot.com",
   messagingSenderId: "932083356120",
   appId: "1:932083356120:web:f95aeb0efd54839e14e2ad",
   measurementId: "G-CQEW1MPS9L"
 };

 // Initialize Firebase
 const app = firebase.initializeApp(firebaseConfig);
 const firestore = app.firestore();
 const storage = getStorage(app)

 // exporting 
 export { app, firestore, storage }