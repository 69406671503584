import {useState, useEffect, useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore, storage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { downloadContext } from '../../../App';

const initialState ={
    name:"",
    email:"",
    contactno:"",
    Name_Of_Institution:"",
    Upload_birth_cert_Ghana_Card_Trascript:"",
    Time_To_Pick_It_Up:"",
    PaymentMode:""
  }

const University_application = () => {
    const [universityApp, setUniversityApp] = useState(initialState)
    const [fileUpload, setFileUpload] = useState(null)
    const [progress, setProgress] = useState(null)

    const {setDownloadUrl} = useContext(downloadContext)

    const handleChange = (e) => {
      e.persist();
      setUniversityApp((oldState) => ({
        ...oldState,
        [e.target.name]: e.target.value,
      }))
    }


    useEffect(() => {
      const uploadFile = () => {
        const name = new Date().getTime() + fileUpload.name;
        const storageRef = ref(storage, fileUpload.name);
        const uploadTask = uploadBytesResumable(storageRef, fileUpload);
  
        uploadTask.on("state_changed", (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch(snapshot.state){
            case "paused":
              console.log("Upload is Pause");
              break;
            case "running":
              console.log("Upload is Running");
              break;
            default:
              break;
          }
        }, (error) => {
          console.log(error)
        },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUniversityApp((Prev) => ({...Prev, UploadDocs: downloadURL}))
              // console.log(downloadURL)
              setDownloadUrl(downloadURL)
            });
          }
        );
      };
  
      fileUpload && uploadFile()
    }, [fileUpload])

  
    const postUniversityApp = async universityApp => {
      const time = Date.now()
      await firestore.collection('UniversityApp-Details').add({
        ...universityApp, 
        postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
      })
    }

    const handleSubmit = async () => {
      await postUniversityApp(universityApp);
      setUniversityApp(initialState);
      // console.log(universityApp)
    }

  return (
    <div>
    <Form>
    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Name</Form.Label>
      <Form.Control 
        onChange={handleChange}
        name="name"
        value={universityApp.name}
        placeholder="Enter Your Full Name" 
        type='text'
      />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Email</Form.Label>
      <Form.Control
        onChange={handleChange} 
        name="email"
        value={universityApp.email}
        placeholder="Enter Email Address"
    />
    </Form.Group>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Contact No.</Form.Label>
        <Form.Control 
          onChange={handleChange} 
          name="contactno"
          value={universityApp.contactno}
          placeholder="Enter Telephone no."
        />
      </Form.Group>
    </Row>


    <Form.Group className='mt-3' controlId="formGridState">
        <Form.Label>Which institution do you want to apply ?</Form.Label>
        <Form.Select
            onChange={handleChange} 
            name="Name_Of_Institution"
            value={universityApp.Name_Of_Institution}
            defaultValue="Choose..."
          >
          <option disabled>Choose...</option>
          <option>KNUST</option>
          <option>UNIVERSITY OF GHANA</option>
          <option>UCC</option>
          <option>UEW</option>
          <option>UDS</option>
          <option>UHAS</option>
          <option>COLLEGE OF EDUCATION</option>
          <option>COLLEGE OF HEALTH (NURSING TRAINING)</option>
          <option>OTHER INSTITUTION</option>
        </Form.Select>
    </Form.Group>

    <Form.Group className="mt-3" controlId="formGridAddress1">
        <Form.Label>For Other Institution, State the name of the Institution</Form.Label>
          <Form.Control
            onChange={handleChange} 
            name="ForOtherInstitution"
            value={universityApp.ForOtherInstitution}
            placeholder="For Other Institution" 
            type='text'
          />
      </Form.Group>

     <Form.Group className="position-relative mb-3 mt-3">
          <Form.Label>Upload biometric birth cert, Ghana card, school transcript and/or WASSCE certificate</Form.Label>
          <Form.Control
            onChange={(event) => {setFileUpload(event.target.files[0])}} 
            name="Upload_birth_cert_Ghana_Card_Trascript"
            type="file"
        />
      </Form.Group>

      <Form.Group className="position-relative mb-3 mt-3">
          <Form.Label>Time and Date to pick it up</Form.Label>
          <Form.Control
            onChange={handleChange} 
            name="Time_To_Pick_It_Up"
            value={universityApp.Time_To_Pick_It_Up}
            type="datetime-local"
        />
      </Form.Group>

    <Form.Group className='mt-3' controlId="formGridState">
        <Form.Label>Payment Mode</Form.Label>
        <Form.Select
            onChange={handleChange} 
            name="PaymentMode"
            value={universityApp.PaymentMode}
            defaultValue="Choose..."
          >
          <option>Choose...</option>
          <option>Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</option>
          <option>Cash</option>
        </Form.Select>
    </Form.Group>
    
    <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
      <Form.Label>If Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</Form.Label>
    </Form.Group>

    <Button variant="primary" className='mt-3' onClick={handleSubmit}>
      Submit
    </Button>
  </Form>
  </div>
  )
}

export default University_application
