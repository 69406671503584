import {useEffect, useState, useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore, storage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import {v4} from "uuid";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { downloadContext } from '../../../App';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';

const initialState ={
  name:"",
  email:"",
  contactno:"",
  Print_Out_Type:"",
  Number_Of_Printouts:"",
  Quantity_copies:"",
  Number_Of_Copies:"",
  TimeToPick:"",
  Description:"",
  PaymentMode:""
}


const Pick_it_up = () => {
  const [printPickitUp, SetPrintPickitUp] = useState(initialState)
  const [fileUpload, setFileUpload] = useState(null)
  const [progress, setProgress] = useState(null)
  const [errors, setErrors] = useState({})
  const [isSubmut, setIsSubmit] = useState(false)

  const {setDownloadUrl} = useContext(downloadContext)

  const navigate = useNavigate();
  

  const handleChange = (e) => {
    e.persist();
    SetPrintPickitUp((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }))
  }

  // const uploadFile = () => {
  //   if(fileUpload == null) return;
  //   const fileRef = ref(storage, `printFiles/${fileUpload.name + v4()}`);
  //   uploadBytes(fileRef, fileUpload).then(() => {
  //     alert("File Uploaded")
  //   })
  // }

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + fileUpload.name;
      const storageRef = ref(storage, fileUpload.name);
      const uploadTask = uploadBytesResumable(storageRef, fileUpload);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch(snapshot.state){
          case "paused":
            console.log("Upload is Pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      }, (error) => {
        console.log(error)
      },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            SetPrintPickitUp((Prev) => ({...Prev, UploadDocs: downloadURL}))
            // console.log(downloadURL)
            setDownloadUrl(downloadURL)
          });
        }
      );
    };

    fileUpload && uploadFile()
  }, [fileUpload])


  const postPrintPick = async printPickitUp => {
    const time = Date.now()
   
    await firestore.collection('print-pick').add({
      ...printPickitUp, 
      postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
    });
  }


  const handleSubmit = async () => {
    await postPrintPick(printPickitUp);
    // await uploadFile(printPickitUp.file = fileUpload);
    SetPrintPickitUp(initialState);
    // setIsSubmit(true);
    navigate("/");
    console.log("work!")
    emailjs.send('Adwinpa Business', 'template_r8m3g08', printPickitUp, '5N7yAAoNEnuylqZNu')
      .then((response) => {
        console.log('Email sent successfully!', response.text);
        Swal.fire('Success', 'Data Submitted and Email sent successfully!', 'success');
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        Swal.fire('Error', 'Failed to send email', 'error');
    });
  }



  return (
    <div>
      <Helmet>
        <title>Print Pickup</title>
        <meta name='description' content='Print and Pick it Up'/>
      </Helmet>
      {isSubmut ? <Spinner active inline="centered" size='huge' />: (
        <>
          <Form>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              onChange={handleChange}
              name="name"
              value={printPickitUp.name}
              placeholder="Enter Your Full Name" 
              type='text'
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={handleChange} 
              name="email"
              value={printPickitUp.email}
              placeholder="Enter Email Address"
          />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Contact No.</Form.Label>
              <Form.Control 
                onChange={handleChange} 
                name="contactno"
                value={printPickitUp.contactno}
                placeholder="Enter Telephone no."
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Colored Print out Or Black & White ?</Form.Label>
              <Form.Control 
                onChange={handleChange} 
                name="Print_Out_Type"
                value={printPickitUp.Print_Out_Type}
                placeholder="Colored Print out Or Black & White"
              />
            </Form.Group>
          </Row>

          <Form.Group className="position-relative mb-3 mt-3">
                <Form.Label>File</Form.Label>
                <Form.Control
                  onChange={(event) => {setFileUpload(event.target.files[0])}} 
                  name="file"
                  // value={printPickitUp.file}
                  type="file"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>How many Printouts ?</Form.Label>
                <Form.Control
                  onChange={handleChange} 
                  name="Number_Of_Printouts"
                  value={printPickitUp.Number_Of_Printouts}
                  placeholder="How many Printouts" 
                />
            </Form.Group>


            <Form.Group className='mt-3' controlId="formGridState">
              <Form.Label>Do you want Photocopy ?</Form.Label>
              <Form.Select
                  onChange={handleChange} 
                  name="Quantity_copies"
                  value={printPickitUp.Quantity_copies}
                  defaultValue="Choose..."
                >
                <option>Choose...</option>
                <option>Yes</option>
                <option>No</option>
              </Form.Select>
          </Form.Group>


            <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
              <Form.Label>If yes how many copies do you want ?</Form.Label>
                <Form.Control
                  onChange={handleChange} 
                  name="Number_Of_Copies"
                  value={printPickitUp.Number_Of_Copies}
                  placeholder="how many copies do you want ?" 
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Time To Pick ?</Form.Label>
                <Form.Control
                  onChange={handleChange} 
                  name="TimeToPick"
                  value={printPickitUp.TimeToPick}
                  placeholder="Time To Pick" 
                  type='Datetime-local'
                />
            </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Describe your work if not mentioned above</Form.Label>
            <Form.Control
              onChange={handleChange} 
              name="Description"
              value={printPickitUp.Description}
              placeholder="eg: If you want an Envelope, Bind your work... etc" />
          </Form.Group>

          <Form.Group className='mt-3' controlId="formGridState">
              <Form.Label>Payment Mode</Form.Label>
              <Form.Select
                  onChange={handleChange} 
                  name="PaymentMode"
                  value={printPickitUp.PaymentMode}
                  defaultValue="Choose..."
                >
                <option>Choose...</option>
                <option>Momo Make payment on <span>055 944 2580</span> Richard Qwofie</option>
                <option>Cash</option>
              </Form.Select>
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
            <Form.Label>If Momo Make payment on <span>055 944 2580  </span> Richard Qwofie</Form.Label>
          </Form.Group>

          <Button variant="primary" className='mt-3' onClick={handleSubmit} disabled={progress !== null && progress < 100}>
            Submit
          </Button>
        </Form>
    </>
    )}
    </div>
  )
}

export default Pick_it_up
