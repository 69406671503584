import {useState, useEffect, useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { firestore, storage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { downloadContext } from '../../../App';


const initialState ={
  name:"",
  email:"",
  contactno:"",
  typeofCard:"",
  quantity:"",
  time:"",
  PaymentMode:""
}

const Buy_card_only = () => {
  const [checkResultsOnly, setCheckResultsOnly] = useState(initialState)
  const [fileUpload, setFileUpload] = useState(null)
  const [progress, setProgress] = useState(null)
  const [errors, setErrors] = useState({})
  const [isSubmut, setIsSubmit] = useState(false)

  const {setDownloadUrl} = useContext(downloadContext)

  const handleChange = (e) => {
    e.persist();
    setCheckResultsOnly((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }))
  }


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + fileUpload.name;
      const storageRef = ref(storage, fileUpload.name);
      const uploadTask = uploadBytesResumable(storageRef, fileUpload);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch(snapshot.state){
          case "paused":
            console.log("Upload is Pause");
            break;
          case "running":
            console.log("Upload is Running");
            break;
          default:
            break;
        }
      }, (error) => {
        console.log(error)
      },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setCheckResultsOnly((Prev) => ({...Prev, UploadDocs: downloadURL}))
            // console.log(downloadURL)
            setDownloadUrl(downloadURL)
          });
        }
      );
    };

    fileUpload && uploadFile()
  }, [fileUpload])



  const postCheckResultsOnly = async checkResultsOnly => {
    const time = Date.now()
    await firestore.collection('Check-Results-Only').add({
      ...checkResultsOnly,
      postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(time),
    })
  }

  const handleSubmit = async () => {
    await postCheckResultsOnly(checkResultsOnly);
    setCheckResultsOnly(initialState);
    // console.log(checkResultsOnly)
  }

  // const handleSelectInput = (e) => {
  //   const inti = initialState.colored
  //   console.log(inti)
  // }

  return (
    <div>
      <Form>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Name</Form.Label>
        <Form.Control 
          onChange={handleChange}
          name="name"
          value={checkResultsOnly.name}
          placeholder="Enter Your Full Name" 
          type='text'
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Email Address for receiving card </Form.Label>
        <Form.Control
          onChange={handleChange} 
          name="email"
          value={checkResultsOnly.email}
          placeholder="Enter Email Address"
      />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Contact No.</Form.Label>
          <Form.Control 
            onChange={handleChange} 
            name="contactno"
            value={checkResultsOnly.contactno}
            placeholder="Enter Telephone no."
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>what type of card do you want to buy  ?</Form.Label>
          <Form.Control 
            onChange={handleChange} 
            name="typeofCard"
            value={checkResultsOnly.typeofCard}
            placeholder="Eg: BECE Results Checker or WASSCE result checker or NOV-DEC result checker"
          />
        </Form.Group>
      </Row>

       <Form.Group className="position-relative mb-3 mt-3">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              onChange={handleChange} 
              name="quantity"
              value={checkResultsOnly.quantity}
              type=""
          />
        </Form.Group>

        <Form.Group className="position-relative mb-3 mt-3">
            <Form.Label>Time to Pick it Up</Form.Label>
            <Form.Control
              onChange={handleChange} 
              name="time"
              value={checkResultsOnly.time}
              type="datetime-local"
          />
        </Form.Group>

        <Form.Group className='mt-3' controlId="formGridState">
            <Form.Label>Payment Mode</Form.Label>
            <Form.Select
                onChange={handleChange} 
                name="PaymentMode"
                value={checkResultsOnly.PaymentMode}
                defaultValue="Choose..."
                >
                <option>Choose...</option>
                <option>Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</option>
                <option>Cash</option>
            </Form.Select>
        </Form.Group>
      
      <Form.Group className="mb-3" controlId="formGridAddress1" hidden>
        <Form.Label>If Momo Make payment on <span>0550724745</span> Fredrick Ankamah Twene</Form.Label>
      </Form.Group>

      <Button variant="primary" className='mt-3' onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
    </div>
  )
}

export default Buy_card_only
