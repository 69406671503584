import { useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../Styles/main.css";
import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Pick_it_up from "../form_dropdowns/print/Pick_it_up";
import Deliver_it_to_me from "../form_dropdowns/print/Deliver_it_to_me";
import Buy_card_only from "../form_dropdowns/check-results/Buy_card_only";
import Buy_card_check_results from "../form_dropdowns/check-results/Buy_card_check_results";
import Birth_cert from "../form_dropdowns/online-application/Birth-cert";
import Passport from "../form_dropdowns/online-application/Passport";
import University_application from "../form_dropdowns/online-application/University-application";
import Job_application from "../form_dropdowns/online-application/Job-application";


function Navigate() {
    // Pick it up
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Delivery To me
    const [showSecond, setShowSecond] = useState(false);
    const handleCloseSecond = () => setShowSecond(false);
    const handleShowSecond = () => setShowSecond(true);
    
    // Check Results Only
    const [showCheckResultsOnly, setShowCheckResultsOnly] = useState(false);
    const handleCloseCheckResultsOnly = () => setShowCheckResultsOnly(false);
    const handleShowCheckResultsOnly = () => setShowCheckResultsOnly(true);

    // Check Results Only
    const [showCheckResults, setShowCheckResults] = useState(false);
    const handleCloseCheckResults = () => setShowCheckResults(false);
    const handleShowCheckResults = () => setShowCheckResults(true);
    
    ///////////////////// Online Application /////////////////////////////
    // Birth Certificate Application 
    const [showBirthCert, setShowBirthCert] = useState(false);
    const handleCloseBirthCert = () => setShowBirthCert(false);
    const handleShowBirthCert = () => setShowBirthCert(true);

    // Passport Application 
    const [showPassport, setShowPassport] = useState(false);
    const handleClosePassport = () => setShowPassport(false);
    const handleShowPassport = () => setShowPassport(true);

     // University Application 
     const [showUniversity, setShowUniversity] = useState(false);
     const handleCloseUniversity = () => setShowUniversity(false);
     const handleShowUniversity = () => setShowUniversity(true);

     // Job Application 
     const [showJob, setShowJob] = useState(false);
     const handleCloseJob = () => setShowJob(false);
     const handleShowJob = () => setShowJob(true);
    

	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	return (
        <div style={{position:"relative", zIndex: "3"}}>
		    <header>
			<h3>ADWINPA <span className="bg-black rounded p-2">BUSINESS</span></h3>
			<nav ref={navRef}>
				<a href="/" className="text-[16px]">HOME</a>
				
                <NavDropdown title="PRINT" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/Print" onClick={handleShow}>Print & Pick it Up</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2" onClick={handleShowSecond}>Print & Deliver to me</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="CHECK RESULTS" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1" onClick={handleShowCheckResultsOnly}>Buy Card Only</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2" onClick={handleShowCheckResults}>Buy Card & Check Results</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="ONLINE APPLICATION" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.2" onClick={handleShowBirthCert}>Birth Certificate</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.1" onClick={handleShowPassport}>Passport</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2" onClick={handleShowUniversity}>University Application</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2" onClick={handleShowJob}>Job Application</NavDropdown.Item>
                </NavDropdown>

                <a href="/design_works" className="text-[16px]">DESIGN WORKS</a>

                <a href="/contact" className="text-[16px]">CONTACT US</a>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button className="nav-btn" onClick={showNavbar}>
				<FaBars />
			</button>
		    </header>


            {/* Print & Pick it Up */}
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header style={{backgroundColor:"#1434A4 "}} closeButton>
                <Modal.Title className="text-white">Print and Pick it Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Pick_it_up />
                </Modal.Body>
                
            </Modal>

            {/* Print & Deliver it to me */}
            <Modal centered show={showSecond} onHide={handleCloseSecond}>
                <Modal.Header closeButton>
                <Modal.Title>Print and Deliver it to me</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Deliver_it_to_me />
                </Modal.Body>
            </Modal>

            {/* Buy Card Only Modal */}
            <Modal centered show={showCheckResultsOnly} onHide={handleCloseCheckResultsOnly}>
                <Modal.Header closeButton>
                <Modal.Title>Buy Card Only</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Buy_card_only />
                </Modal.Body>
            </Modal>

            {/* Buy card check results */}
            <Modal centered show={showCheckResults} onHide={handleCloseCheckResults}>
                <Modal.Header closeButton>
                <Modal.Title>Buy Card & Check Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Buy_card_check_results />
                </Modal.Body>
            </Modal>

            {/* Birth Certificate Application */}
            <Modal centered show={showBirthCert} onHide={handleCloseBirthCert}>
                <Modal.Header closeButton>
                <Modal.Title>Application For Biometric Birth Certificate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Birth_cert />
                </Modal.Body>
            </Modal>

            {/* Passport Application */}
            <Modal centered show={showPassport} onHide={handleClosePassport}>
                <Modal.Header closeButton>
                <Modal.Title>Passport Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Passport />
                </Modal.Body>
            </Modal>

            {/* University Application */}
            <Modal centered show={showUniversity} onHide={handleCloseUniversity}>
                <Modal.Header closeButton>
                <Modal.Title>University Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <University_application />
                </Modal.Body>
            </Modal>

             {/* Job Application */}
             <Modal centered show={showJob} onHide={handleCloseJob}>
                <Modal.Header closeButton>
                <Modal.Title>Job Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Job_application />
                </Modal.Body>
            </Modal>
        </div>
	);
}

export default Navigate;

