import React from 'react'

const Print_works = () => {
  return (
    <div>
      print_works
    </div>
  )
}

export default Print_works
